// @ts-check
import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from '@reach/router'
import './Navbar.css'

const Navbar = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "ang-logo-2400w.png" }) {
            childImageSharp {
              fixed(width: 180, height: 32) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <nav
          className="Navbar navbar"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <a className="navbar-item" href="https://policy.angeleye.health">
                <Img fixed={data.file.childImageSharp.fixed} />
              </a>
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
              <div className="navbar-start">
                <Link className="navbar-item" to="/">
                  Policies {'&'} Procedures
                </Link>
              </div>
            </div>
          </div>
        </nav>
      )}
    />
  )
}

export default Navbar
