// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from '@reach/router'
import Navbar from './Navbar'
import 'bulma/css/bulma.css'

const Sidebar = ({ data }) => (
  <aside className="menu">
    <p className="menu-label">Sections</p>
    <ul className="menu-list">
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <li key={node.fields.slug}>
          <Link
            to={node.fields.slug}
            getProps={({ isCurrent }) => ({
              className: isCurrent ? 'is-active' : '',
            })}
          >
            {node.frontmatter.title}
          </Link>
        </li>
      ))}
    </ul>
  </aside>
)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
          sort: { fields: [frontmatter___sectionNumber], order: ASC }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                sectionNumber
              }
              html
              headings {
                value
                depth
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: "AngelEye Health Policies and Procedure",
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Navbar siteTitle={data.site.siteMetadata.title} />
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column" style={{ maxWidth: 350 }}>
                <Sidebar data={data} />
              </div>
              <div className="column">{children}</div>
            </div>
          </div>
        </section>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
